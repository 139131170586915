<template>
  <div class="customerManagement-view">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="底部导航" name="first">
        <tabSetting
          v-if="activeName == 'first'"
        />
      </el-tab-pane>
      <el-tab-pane label="顶部背景色配置" name="second">
        <bgSetting
          v-if="activeName == 'second'"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import tabSetting from "./components/tabSetting";
import bgSetting from "./components/bgSetting";

export default {
  name: "customer-management",
  components: {
    tabSetting,
    bgSetting
  },
  data() {
    return {
      activeName: "first",
    };
  },
  // watch: {
  //   activeName(val, lod) {
  //     switch (val) {
  //       case "first":
  //         this.customerState = 1;
  //         break;
  //       case "second":
  //         this.customerState = 2;
  //         break;
  //       case "third":
  //         this.customerState = 3;
  //         break;
  //       default:
  //         return;
  //     }
  //   },
  // },
  
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
    
  },
};
</script>

<style lang="scss" scoped>
</style>